import React, { Component } from "react";
import FullTypeRow from './FullTypeRow';
import axios from 'axios';
import { Link, useLocation, useNavigate,  useParams } from 'react-router-dom';
import { Col, Row, Form} from "react-bootstrap";


//This  endpoint  was written initially to handle compex data types and then has been backed off - 
// thus there is unused code

const strBEURL = 'clammytest.assertions.ca'   // back end url for prod  BE
// this will use BE get model /amodel/user-model/  which will expect the cookie to be in place

const showFeedback = (feedback => {
  if (document.getElementById("messagePlaceType") ){
    document.getElementById("messagePlaceType").innerHTML = feedback
  }
})



function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}


export default withRouter(
class EditFulltype extends Component {
// This has idANdName as parameter (called from Nav)
// it uses ep type-for-assertion to ensure that this has a type and attribute  -- so far this seems to work.
// but when it gets typelist it can find subtypes they have not been structured right to find their way into full attributes ( however cubicle does find them ) 

  constructor(props) {
      super(props)
      this.setState({
        FullHierarchy: [],
        strAssnGUID: 'sag',
        assnname:'an',
        authname: 'auth',
        list: []
      });
      this.handleChangeAttrList = this.handleChangeAttrList.bind(this)
  }

  componentDidMount() {
    this.getAssertionType()
  }
  componentDidUpdate() {
    // bad news   this.getAssertionType()   causes loop
  }
  getAssertionType() {

      let typeArgs = JSON.parse(this.props.router.params.typeArgs)   // this is really where we need to seee this appearing
      let strAssnGUID = typeArgs.strAssnGUID
      let assnname = typeArgs.Name
      let AuthName = typeArgs.AuthName

      this.setState({
        FullHierarchy: [],    // the object model intended to include subtyupes - now only one type
        strAssnGUID: strAssnGUID,
        assnname: assnname,
        AuthName:  AuthName,
      });

      let url = 'https://' +  'clammytest.assertions.ca' + '/type/type-for-assertion/?strAssnGUID=' + strAssnGUID + '&NewTypeName=' + assnname
      axios.get(url)  // this should ensure type is there
      .then(  nada => { 
         //url = 'https://' +  'clammycubicle.assertions.ca' + '/type/type-list/?strAssnGUID=' + strAssnGUID + '&ModelID=' + typeArgs.ModelID
         url = 'https://' +  'clammytest.assertions.ca' + '/type/type-list/?strAssnGUID=' + strAssnGUID + '&ModelID=' + typeArgs.ModelID
        return axios.get(url)})
      .then(hierlist => { 
        this.setState({
          FullHierarchy: hierlist.data,
        });
        showFeedback(  hierlist.data[0].typeName)
   
      })
      .catch((error) => {
        showFeedback(' Mount error for edit full type: ' + error)
      }) 

  }

  handleChangeAttrList() {  //  this fn to be sent to chold to get it to invoke refresh the attr list  // this does not work so comment out
    this.getAssertionType()    // does this need bind??
  }
   

  render() {
      //var inputConnections = this.state.InputConnections
    console.debug('rendering in edit type');
    let tlist = []
    let assnName = '-'
    let authName = '-'
    if (this.state == null ) { 
      tlist = []
      assnName = '-'
    } else {
      tlist = this.state.FullHierarchy
      assnName = this.state.assnname
      authName = this.state.AuthName
    }
    return (
      <div >
        <Row>
          <Col md={7}>      
            {tlist.length} type(s) found for :  &nbsp;  
            <font size="4" ><b>{assnName}</b></font> <font size="2" color="green" >&nbsp; &nbsp; (by {authName})</font></Col>
          <Col md={{ span: 6, offset: 6 }}>  
            <p align="right">
              <Link
                className="nav-link" path={"/:KNID"}     //    className="edit-link" path={"assertion/:id"}
                to={'/explain/' + 'KN255'}   // hardcoded so when it comes off the Nav bar it has a place to open - ie intro for all  ---  when hitting explain from a particular panel the buton will pass the panel id
              >
                Explain&#62;
              </Link>
            </p> 
          </Col>
        </Row>

        {tlist.map( (eachfullType,i) => {
          return <FullTypeRow handleChangeAttrList = {this.handleChangeAttrList} thisFullType={eachfullType} key={i} />;   // Handler not needed
        })}
        <div id="messagePlaceType" class="alert alert-primary" role="alert">
          Messages 
        </div>
      </div>
    )
  }
}  // end EditFullType Component
)