import React, { Component } from 'react'
import  { Navigate } from 'react-router-dom'

import axios from 'axios'
import Button from 'react-bootstrap/Button'
import { renderToString } from 'react-dom/server'
const strBEURL = 'clammytest.assertions.ca'   // back end url for prod  BE


//export const MyComponent = (props) => {
export default class HTMLReport extends Component {

  constructor(props) {
   
    super(props)
     this.state = {
      hstring: 'Please wait for assembly.  About 10 secs....'
    };
  }

  componentDidMount() {
    /*     */
    let strParams = "?token=" + localStorage.getItem('USER_MODEL_TOKEN')
    let url = 'https://' +  strBEURL + '/hyperrep/fullhtml/' + strParams

    axios.get(url)
      .then((res) => {
        this.setState({
          hstring: res.data
        });
       
        
      })
      .catch((error) => {
        this.setState({
          hstring: error
        });
      })
 
  }

    render() {

// hstring now contains a message not the html


       return <Navigate to={this.state.hstring } />
    

}



     


  




}

 






