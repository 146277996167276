import React, { Component }  from "react";
import { Col, Row} from "react-bootstrap";
import Canvas from './canvas.component';
import Button from 'react-bootstrap/Button'
import { Form} from "react-bootstrap";
import axios from 'axios';
import { Link } from 'react-router-dom';
import html2canvas from 'html2canvas';
//import { Canvg } from 'canvg';  // used to get connectors svg into image -- I hope
//import {Canvg} from 'canvg';
import Select from "react-select";   // used for multiselect mambers
import DiagramOptionRow from './DiagramOptionRow.js'

let strBEURL = 'clammytest.assertions.ca'
// a utility fn
const showFeedback = ((feedback,place) => {
    if (document.getElementById(place) ){
      document.getElementById(place).innerHTML = feedback
    }
  })
// CODING NOTE   !!!
// the data structures here, although they might  work, are a mess   id is sometimed dbid and somtiems guid .  Great opportunity for clean up 240225
 
export default class Diagrammer extends Component {

    constructor(props) {
        super(props)

        // ------- Binding -------------------
        this.onChangeDiagramName = this.onChangeDiagramName.bind(this)
        this.onChangeDiagramDescription = this.onChangeDiagramDescription.bind(this)
        this.onChangeDiagramModelNote = this.onChangeDiagramModelNote.bind(this)
        this.onChangeDiagram = this.onChangeDiagram.bind(this)
        this.deletePerspective = this.deletePerspective.bind(this)
        this.onChangeDiagram = this.onChangeDiagram.bind(this); // select from diagrams
        this.hdlrSelectAssn = this.hdlrSelectAssn.bind(this)   // rclick
        this.hndlrMemberChange = this.hndlrMemberChange.bind(this)  // selected member
        this.updateDiagram = this.updateDiagram.bind(this)  // update
        this.addNewPerspective = this.addNewPerspective.bind(this)

        this.onChangeExtraElements = this.onChangeExtraElements.bind(this)
        this.onChangeseq = this.onChangeseq.bind(this)
        this.onChangehyperSeq = this.onChangehyperSeq.bind(this)

    
    // Initial State

    let cpd = localStorage.getItem('CURRENT_PERSPECTIVE_GUID')
    
        this.state = {
            FullDiagrams: [],
            GotDiagram: {},
            ListDiagrams: [],  
            selectedDBID: 'none',
            selectedAssn: '-right click to select',
            mOptionsSelected: null,    // think this is array of ids
           
            FullPerspectives: [],   // pulled from Full Perspective
            ModelConnections: [], // pulled from Full Perspective
            assnOptions: [],  // all assertions
            currentPerspectiveGUID: cpd
        }
    }
 
 // ===48=============== COMPONENT MOUNT ====================================================================

    componentDidMount() {
        this.fetchFullPespectives()
    }

    fetchFullPespectives(){  

        let listDiagrams
        let selectedOptions
        let strParams = "?token=" + localStorage.getItem('USER_MODEL_TOKEN')
        axios.get( 'https://' +  strBEURL +   '/diagram/full-perspectives/' + strParams )  // this gets pair: all the diagrams and the connections
        .then(res => {  
            if (!Array.isArray(res.data)){
            this.showfeedback('Error in getting full perspective from api.','messagePlace')
            }
            this.setState({
                FullPerspectives: res.data[0],
                ModelConnections: res.data[1]
            }) // each perspective and its diagramObjects

            if (res.data[0].length > 0) {   
                this.setState({
                    //currentPerspectiveGUID: res.data[0][0].strGUID,   // set the first in the list
                })
                listDiagrams = res.data[0].map(per =>{
                    return {strGUID: per.strGUID,  Name: per.Name, elemCount: per.diagramobjects.length}
                })
                //selectedOptions = null
// the currentperspectiveGUID exists from the construct
            // find the index into res.data[0] where the strGUID is a hit   KLUDGIE
                let currentIndex = 0
                let thisIndex = 0
                res.data[0].forEach(persp => {    //set the dobs from the first perspective for select members
                        if (persp.strGUID === this.state.currentPerspectiveGUID) {currentIndex = thisIndex}
                        thisIndex += 1
                })

                selectedOptions = res.data[0][currentIndex].diagramobjects.map(dob => {    //set the dobs from the first perspective for select members
                    return { id: dob.dbid,  strVAssnGUID: dob.strVAssnGUID, GUID: dob.strAssnGUID, value: dob.dbid, label:  dob.Name}   // Name mus have be concat in tht db if spec id is to show
                })
                this.setState({
                    GotDiagram: res.data[0][currentIndex],   // GotDiagram is the state of the current perspective being shown - changes to name and desc change state and update diagram updates positions so it is ready to be sent  for an update to db
                    ListDiagrams: listDiagrams,
                    mOptionsSelected: selectedOptions,
                })
            }
            let url = 'https://clammytest.assertions.ca/assertions/' + strParams
            return axios.get(url)     // get the assertions list to build the state assnOptions  id value label
        })
        .then(listAssertions => {  
            let listAssnOptions =  []
            listAssertions.data.forEach( assn => {
                listAssnOptions.push({dbid: assn.dbid,  GUID: assn.strGUID, value: assn.dbid, label: assn.SpecID + ' - ' + assn.Name, name: assn.Name , specID: assn.SpecID })
            })
            this.setState({  assnOptions: listAssnOptions   })    // assnOptions is all assertions that might be included in this view ( ie all assns)
            })   
            .catch( error => {   
            showFeedback('Get Diagrams error= ' + JSON.stringify(error),'messagePlace')
        })
};

 // =====103============= SUB COMPONENTS ====================================================================

    OptionList(diagramList) {
        //console.log(diagramList)
        if(diagramList){
            return diagramList.map((obj, i) => {         // i is the index in the array
              return <DiagramOptionRow obj={obj} key={i} />;
            });
        }
    }


 // =====115============= ON CHANGE FUNCTIONS ====================================================================

    onChangeDiagramName(e) {
        let latestDiagram = this.state.GotDiagram
        latestDiagram.Name = e.target.value
        this.setState({ GotDiagram: latestDiagram })
    }

    onChangeDiagramDescription(e) {
        let latestDiagram = this.state.GotDiagram
        latestDiagram.Description = e.target.value
        this.setState({ GotDiagram: latestDiagram })
    }

    onChangeDiagramModelNote(e) {
        let latestDiagram = this.state.GotDiagram
        latestDiagram.ModelNote = e.target.value
        this.setState({ GotDiagram: latestDiagram })
    }

    onChangeseq(e) {
        let latestDiagram = this.state.GotDiagram
        latestDiagram.seq = e.target.value
        this.setState({ GotDiagram: latestDiagram })
    }

    onChangeExtraElements(e) {
        let latestDiagram = this.state.GotDiagram
        latestDiagram.ExtraElements = e.target.value
        this.setState({ GotDiagram: latestDiagram })
    }
    onChangehyperSeq(e) {
        let latestDiagram = this.state.GotDiagram
        latestDiagram.hyperSeq = e.target.value
        this.setState({ GotDiagram: latestDiagram })
    }










    onChangeDiagram(e) {

        let selectedDgm = this.getDiagramForGUID(e.target.value)

        let selectedOptions = selectedDgm.diagramobjects.map(dob => {
            return { id: dob.dbid, strVAssnGUID: dob.strVAssnGUID,  GUID: dob.strAssnGUID, value: dob.dbid, label:  dob.Name}   // Name mus have be concat in tht db if spec id is to show
        })
        this.setState({ 
            GotDiagram: selectedDgm,
            mOptionsSelected: selectedOptions,
            currentPerspectiveGUID: selectedDgm.strGUID
        })   // this should update the canvas?- yes because state change
        window.localStorage.setItem('CURRENT_PERSPECTIVE_GUID', selectedDgm.strGUID); 
        document.getElementById("messagePlace").innerHTML = " Rendering:  " + this.state.GotDiagram.Name  ;
    }

     // =====144============= CRUD FUNCTIONS ===========================================MemberUpdate , deletePerspective< =========================

 // ----------------MEMBER UPDATE --------------------------   

    MemberUpdate(blnCompeteUpdate,selected) {   // selected does not have id just dbid
        // Calculates the delta and if blnCompeteUpdate then actually updates with be call
        // This returns a message for the showPlace of actual change or pending change - calculated each time
        // for each assertion 
            // if in dob and not selected remove  - mark action and act if bln
            // if not in dob and in selected then add at 0,0
            
            if (!Array.isArray(this.state.GotDiagram.diagramobjects)){return 'no diagram set up'}

        let dobGUIDS = this.state.GotDiagram.diagramobjects.map(dob => {return dob.strAssnGUID})    // array of assnGUIDS currently on diagram
        let selectedGUIDs = selected.map(sel => {return sel.GUID })
        let addList = ''
        let removeList = ''
        let arrRemoveAssnGUIDs = []
        let arrViewassertionsToAdd  = []

        //console.log(this.state.assnOptions)
        
        this.state.assnOptions.forEach((assn) => {  // where assn structure is {id: assn.id, strVAssnGUID: strVAssnGUID, GUID: assn.strGUID, value: assn.id, label: assn.SpecID + ' - ' + assn.Name}
            if (dobGUIDS.includes(assn.GUID)) {
                if (!(selectedGUIDs.includes(assn.GUID)) ){    // IE if it is not selecte but in the dobs the it is to be removed
                    if (blnCompeteUpdate) {
                        arrRemoveAssnGUIDs.push(assn.GUID)  // add to the list to be removed 
                    }    
                    removeList += ' &nbsp; &nbsp;        ' + assn.label   + ',<br>'
                }
            } else {
                if (selectedGUIDs.includes(assn.GUID)) {  
                    if (blnCompeteUpdate) {
                        // add if boolean  
                            arrViewassertionsToAdd.push({
                                "strGUID": "AddNewGUIDinBE",    // this is created in BE for each av to add just before bulk insert
                                "dbid": assn.dbid,     //   this is wrong  --  assn should have SpecID, Name, dbid to go into the vassn
                                //"id": assn.dbid,  //hedging bets
                                //"VAssnGUID": this.state.GotDiagram.strVAssnGUID,  // same as strGUID -- not needed -- shouuld be created by the insert View members
                                "strAssnGUID": assn.GUID,  
                                "strViewGUID": this.state.GotDiagram.strGUID,   
                                "ModelID": this.state.GotDiagram.ModelID,  
                                "SpecID": assn.specID, 
                                "Name": assn.specID +  ' - ' +assn.name, 
                                "XPos": 10, 
                                "YPos": 10, 
                                "Sequence": 0 
                            })
                    } 
                    addList += '&nbsp; &nbsp;      ' + assn.label  + ',<br>'// add to 'add' ' list
                }
            }
        })   // end assertions
console.log(arrViewassertionsToAdd)

        if (blnCompeteUpdate) {
            let strUpdateResult = 'Member Update Result:<br>'
            let deleteRequest = { "strViewGUID": this.state.GotDiagram.strGUID,
                        "arrAssnGUIDs": arrRemoveAssnGUIDs
            }
        // assume resullt is of standard format  { ok: true ,  message: 'deleted 3'}
            axios.post( 'http://' +  strBEURL + '/diagram/delete-view-members/' , deleteRequest)
            .then((res) => {
             strUpdateResult +=  res.data.message + '<br>'    // see the message regardless
                //showFeedback(' Removed Members OK: ' + res.data,'messagePlace')
                return axios.post( 'http://' +  strBEURL + '/diagram/insert-view-members/' , arrViewassertionsToAdd)
            })
            .catch((error) => {   // this catches failed delete
                strUpdateResult += '  Failed delete with message: '  + error  + '  Continue to add. ' 
                //showFeedback( ' Remove Members ERROR: ' + error,'messagePlace')  
                return axios.post( 'http://' +  strBEURL + '/diagram/insert-view-members/' , arrViewassertionsToAdd)
            })
            .then((res) => {
                strUpdateResult +=  res.data.message + '<br> '    // expecting an string
                //showFeedback(' Added Members OK: ' + res.data,'messagePlace')
            })
            .catch((error) => {
                strUpdateResult += '  Failed add with message: '  + error  
                //showFeedback( ' Add Members ERROR: ' + error,'messagePlace')  
            })
            .finally(() =>{
            
                this.fetchFullPespectives()    // to update
                showFeedback( strUpdateResult,'messagePlaceMembers') 
            })
        }
        return 'On Update WILL add : <br>'  + addList + ' and WILL remove: <br>' + removeList
    }
      
    


 // -----231-----------UPDATE PERSPECTIVE --------------------------   
    updateDiagram(e){
        // update positions// the diagram objects should have a strGUID which should show in newElements
        // Get the new positions into an update object
        let thisEl
        let thisDiagram = this.state.GotDiagram
        let cancon = document.getElementById("canvasContainer") 

        let guid = this.state.GotDiagram.strGUID
        let model = this.state.GotDiagram.ModelID
        let saveQuery =  '?strViewGUID=' + guid + '&UserModelID=' + model 
        let fullHTTPRequest = 'https://' +  strBEURL +   '/xprs-image/save-image' + saveQuery



        // this gets each dob and finds its posn OK and then sets its new posn =--  This is working OK!!

        showFeedback('Updating Diagram' ,'messagePlace')

    
        let newElements = thisDiagram.diagramobjects.map(el =>{   
            thisEl = document.getElementById( 'dgm_' + el.dbid)          //  this might not work
            el.XPos = thisEl.getBoundingClientRect().left - 50   // kludge for origin  - to be fixed
            el.YPos = thisEl.getBoundingClientRect().top - 10
            return el
        })  
        // message elenents updated
        let strPosList = 'Positions \n\r'
        newElements.forEach(el => {
            strPosList += el.strGUID + '-- ' + el.Name + '  (' + el.XPos + ',' + el.YPos + ')  <br>'
        })
        showFeedback('UPDATING with elements=' +  strPosList, 'messagePlace')
    
        //prep axios request
        let dgmToUpdate = { strGUID: this.state.GotDiagram.strGUID,
            Name: this.state.GotDiagram.Name,
            Description: this.state.GotDiagram.Description,
            ModelNote: this.state.GotDiagram.ModelNote,
            elements: newElements
        }
            // Now just use axios to Post the update
        
        axios.post( 
            'https://' +  strBEURL +   '/diagram/update-full-perspective/',
            JSON.stringify(dgmToUpdate),
            {
                headers: {'content-type': 'application/json'},
                crossOrigin: true,
            }
        )  
        .then(res => {
        showFeedback('UPDATE RESULT=' + JSON.stringify(res.data),'messagePlace')
        })
        .catch((error) => {
            showFeedback('error= ' + JSON.stringify(error),'messagePlace')
        })  

        // Save what we got at least -------------------------------------------------
        showFeedback('Saving Diagram Image to File' ,'messagePlace')


        html2canvas(cancon)
        .then(function(canvas) {
            canvas.toBlob( (blob) => { 
                    axios.post(fullHTTPRequest, blob, {    //  now just write be endpoint for this just to save it
                    headers: {
                    'Content-Type': 'image/png'
                    }
                })
                .then( ans =>{
                    showFeedback('Perspective and its diagram in Hyperrep are updated.' )
                    //showFeedback('UPDATE  DIAGRAM IMAGE RESULTT=' + JSON.stringify(ans.data) + '.  fullHTTPRequest: ' + fullHTTPRequest,'messagePlace') // hide so the message on update shows 
                })
                .catch((error) => {
                    showFeedback('UPDATE  DIAGRAM IMAGE RESULT ERROR:=' + error,'messagePlace') 
                    alert(' save image error : ' + error) 
                })
            })
    
       })
        
    }



 // ----------------DELETE PERSPECTIVE --------------------------    
// need to get current diagram and its strViewGUID

    deletePerspective(e) {
        showFeedback('Starting delete for got dgm: ' + this.state.GotDiagram.strGUID,'messagePlace')
       let strDeleteViewGUID = this.state.GotDiagram.strGUID
      
        axios.get( 'https://' +  strBEURL + '/diagram/delete-perspective/?strViewGUID=' + strDeleteViewGUID,)  // this works but errors to 500
        .then((res) => {
            if (res.data.OK) {showFeedback( res.data.message,'messagePlace')}
              this.fetchFullPespectives()
        })
        .catch((error) => {
            showFeedback( 'FAILED in delete with error: ' + error)
        })
    }

     addNewPerspective(e){
        //showFeedback('TO BE WRITTEN - Add New Perspective. BE is done','messagePlace')
        showFeedback('Starting add new perspective ' ,'messagePlace')
        let strParams = "?token=" + localStorage.getItem('USER_MODEL_TOKEN')
        axios.get( 'http://' +  strBEURL + '/diagram/add-perspective/' + strParams,)
        .then((res) => {
            showFeedback(' ADD OK: ' + res.data,'messagePlace')
               this.fetchFullPespectives() 
        })
        .catch((error) => {
            showFeedback( ' ADD ERROR: ' + error,'messagePlace')  
        })
    }


   // === 249======  HANDLER FUNCTIONS =============hdlrSelectAssn====hndlrMemberChange===========================================
    hdlrSelectAssn = (elSel) => { 
        this.setState({ selectedDBID: elSel.dbid}) 
        this.setState({ selectedAssn: elSel.asnName}) 
        console.log('Setting State selectedDBID: ' + elSel.dbid + '- '+ elSel.asnName)   //   IS THIS CORRECT ?????
    }  // used by child canvas  - passed down

    hndlrMemberChange = (selected) => {

        showFeedback('selected= ' + JSON.stringify(selected),'messagePlaceMembers')
        this.setState({mOptionsSelected: selected})
        showFeedback(this.MemberUpdate(false,selected),'messagePlaceMembers')
    };
    // ====262============== SUPPORT FUNCTIONS getDiagramForGUID

    getDiagramForGUID (strGUID) {
        let dgm = this.state.FullPerspectives.filter(dgm => {
        return dgm.strGUID === strGUID
        })   // array of 1
        return dgm[0]
    }

 // ====273============== RENDER ====================================================================

    render() {

        if (this.state){return (
            <div >
                <p align="right">
                    <Link
                        className="nav-link" path={"/:KNID"}     //    className="edit-link" path={"assertion/:id"}
                        to={'/explain/KN11'}   // hardcoded so when it comes off the Nav bar it has a place to open - ie intro for all  ---  when hitting explain from a particular panel the buton will pass the panel id
                    >
                    Explain&#62;
                    </Link>
                </p>
             <Row>
              {/**   <Col xs={4}><p style={{ fontSize: 32, padding: 3 , color: "navy", width: "600px"}}>{this.state.GotDiagram.Name }</p></Col>*/}
                <Col xs={10}>
                    <Form.Control 
                                size="lg"    
                                as="select" 
                                custom  
                                onChange={this.onChangeDiagram}
                                style={{ fontSize: 26, padding: 3 , color: "navy", width: "600px"}}
                                value={this.state.currentPerspectiveGUID}
                            >
                                {this.OptionList(this.state.ListDiagrams)}
                            </Form.Control>
                            </Col><Col xs={2}>
                        <Button onClick={this.addNewPerspective}  >
                        New
                        </Button>
                            &nbsp;&nbsp;
                        <Button variant='danger' onClick={this.deletePerspective}  >
                            Delete
                        </Button> 
                    
              
                </Col>
            </Row>
                <Canvas   diagram={this.state.GotDiagram}   connections={this.state.ModelConnections} hdlrSelectAssn = {this.hdlrSelectAssn}  />   
                <Row>
                    <Col>
                    <p>
                        <Link
                            className="edit-link" //path={"/edit-assertion/:id"}     //    className="edit-link" path={"assertion/:id"}
                            to={'/edit-assertion/' + this.state.selectedDBID}
                            >
                            Edit Selected Assertion: ___      {this.state.selectedAssn}
                        </Link>
                    </p>

                    <Form  class="form-inline" onSubmit={this.onSubmit}> 

                    <Button onClick={this.updateDiagram} size="sm" variant="warning">
                        Update Perspective
                        </Button>

                        <Form.Group controlId="Diagram-name-choice" >

                        </Form.Group> 
                        <Row>
                        <span class="square border border-4" >
                            <Form.Group controlId="Diagram-name" >
                                <Form.Label >Perspective Name </Form.Label>
                                    <Form.Control 
                                        size="lg"
                                        type="textarea" 
                                        value={this.state.GotDiagram.Name } 
                                        onChange={this.onChangeDiagramName } 
                                    />
                            </Form.Group> 
                            <Form.Group controlId="Diagram-description" >
                                <Form.Label>Perspective Description </Form.Label>
                                    <Form.Control 
                                        as="textarea" 
                                        value={this.state.GotDiagram.Description } 
                                        onChange={this.onChangeDiagramDescription } 
                                        rows={4}
                                    />
                            </Form.Group> 
                            <Form.Group controlId="model-note" >
                                <Form.Label>Perspective Model Note </Form.Label>
                                    <Form.Control 
                                        as="textarea" 
                                        value={this.state.GotDiagram.ModelNote } 
                                        onChange={this.onChangeDiagramModelNote } 
                                        rows={4}
                                    />
                            </Form.Group> 
                            <Form.Group controlId="extra-elements" >
                                <Form.Label>Extra Elements </Form.Label>
                                    <Form.Control 
                                        as="textarea" 
                                        value={this.state.GotDiagram.ExtraElements } 
                                        onChange={this.onChangeExtraElements } 
                                        rows={2}
                                    />
                            </Form.Group>                    
                            <Row>
                                <Col  xs={5}>                            
                                    <Form.Group controlId="model-note" >
                                    <Form.Label>Sequence </Form.Label>
                                        <Form.Control 
                                            as="textarea" 
                                            value={this.state.GotDiagram.seq } 
                                            onChange={this.onChangeseq } 
                                            rows={1}
                                        />
                                    </Form.Group> 
                                </Col>
                                <Col xs={5}>                            
                                    <Form.Group controlId="model-note" >
                                    <Form.Label>HyperRep Sequence </Form.Label>
                                        <Form.Control 
                                            as="textarea" 
                                            value={this.state.GotDiagram.hyperSeq } 
                                            onChange={this.onChangehyperSeq } 
                                            rows={1}
                                        />
                                    </Form.Group> 
                                </Col>
                            </Row>

                         
                    <div id="messagePlace" class="alert alert-primary" role="alert">
                    .. diagram details
                    </div>
                        </span>
                </Row>

          
                       
                        </Form>
                    
                        </Col>
                        <Col>
                            <p></p>
                            <p>Included Assertions:-</p>
                            <Select
                                isMulti
                                placeholder="none"
                                onChange={this.hndlrMemberChange}
                                value={this.state.mOptionsSelected}
                                options={this.state.assnOptions}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                />
                            <div id="messagePlaceMembers" class="alert alert-primary" role="alert">
                                .. membership details (change expected)
                            </div>
                            <Button onClick={() => { 
                                    showFeedback(this.MemberUpdate(true,this.state.mOptionsSelected),'messagePlaceMembers')
                            }} size="sm" variant="warning">
                                Update members of this Perspective
                            </Button>
                        </Col>
                    </Row>
       
              
            </div>
       );}
       else{return(null)}

    }  // end render
}


