import React, { Component } from 'react'
import AttributeTableRow from './AttributeTableRow';
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { Col, Row, Form} from "react-bootstrap";

const strBEURL = 'clammytest.assertions.ca'   //import Table from 'react-bootstrap/Table'; not used here
//    ' +  strBEURL + '

const showFeedback = (feedback => {
  if (document.getElementById("messagePlaceType") ){
    document.getElementById("messagePlaceType").innerHTML = feedback
  }
})

export default class FullTypeRow extends Component {
  constructor(props) {
    super(props)
    console.log('props: ' + JSON.stringify(this.props))
    this.state = {
      strTypeName: '',
      strTypeGUID: '',
      obAttributes: '',
      ModelNote: '?',
    }     
    this.addAttribute = this.addAttribute.bind(this)
    this.onChangeModelNote = this.onChangeModelNote.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.handleDeleteAttr = this.handleDeleteAttr.bind(this)
  }

  componentDidMount() {
    this.setState({
        strTypeName: this.props.thisFullType.typeName,
        strTypeGUID: this.props.thisFullType.strTypeGUID,
        obAttributes: this.props.thisFullType.attributes,
        ModelNote: this.props.thisFullType.ModelNote,
    })
  }
 
  handleDeleteAttr() {  // sent to subcomponent and invoked there
    this.props.handleChangeAttrList()   // pass up chain to compenent that gets the data   // invoke parent refresh
  }
   
  onChangeModelNote(e) {
      this.setState({ ModelNote: e.target.value })
    // this is not needed the submit will update it
    /* HIDE 

    let addTypeURL = 'https://' +  strBEURL + '/type/update-type/?strTypeGUID=' + this.state.strTypeGUID + '&ModelNote=' + e.target.value
    axios.get(addTypeURL)    
        .then((res) => {         // should return array of attrs or messages
          showFeedback('Model Note Updated to : ' + e.target.value)
        })
        .catch(function (error) {
          showFeedback('Model Note Update ERROR: ' + error)
      })
       HIDE */
  }

  addAttribute(){
    console.log('In Add Attribute trying: https://' +  strBEURL + '/attribute/add-attribute/?strTypeGUID=' + this.state.strTypeGUID)

    let addAttributeURL = 'https://' +  strBEURL + '/attribute/add-attribute/?strTypeGUID=' + this.state.strTypeGUID
    axios.get(addAttributeURL)    
        .then((res) => {         // should return array of attrs or messages
         
          showFeedback(  ' Added new Attribute To Type: ' + this.state.strTypeName + '<br> Please go back to assertion and re-enter to refresh list.(if I fail to do it)') 
          this.props.handleChangeAttrList()     //  this should refresh the types - passed from edit-full-type
       
        })
        .catch(function (error) {
       
              showFeedback( ' ADD ATTRIBUTE  ERROR: ' + error)
       
        })
         this.props.handleChangeAttrList()  // invoke parent refresh
      }




  onSubmit(e) {   // initiated by Update Assertion Button
    e.preventDefault()

    let strParam = "strTypeGUID=" + this.state.strTypeGUID  +"&Name=" + this.state.strTypeName  + "&ModelNote=" + this.state.ModelNote    
  
    axios.get('https://clammytest.assertions.ca/type/update-type?'  + strParam)
      .then((res) => {

        if (document.getElementById("messagePlace") ){
          showFeedback( ' Model Note Updated ' )
        }
      })
      .catch(function (error) {
        if (document.getElementById("messagePlace") ){
          showFeedback( ' Model Note UPDATE ERROR: ' + error )
        }
      })
  }

  render() {
    return(
      <div className="table-wrapper">
          <p><font size="6">{this.props.thisFullType.typeName}</font> Frame</p>
          <Form  class="form-inline" onSubmit={this.onSubmit}> 
            <Row>
              <Col>
                <Form.Group  controlId="ModelNote" >
                  <Form.Label >Frame Note
                    <Form.Control 
                     style={{ fontSize: 16, padding: 6 , color: "black", width: "900px"}}
          

                      type="text" 
                      as="textarea" 
                      rows={2}
                      value={this.state.ModelNote} 
                      onChange={this.onChangeModelNote}
                
                      />
                  </Form.Label>
                </Form.Group>
              </Col>
              <Col>
                <Button variant="warning"  block="block" type="submit" className="mt-4">
                              Update Frame Note
                </Button>
              </Col>
            </Row>
          </Form>
        <p>
          <div className="table-wrapper">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Is Cln</th>
                  <th>Is Prim</th>
                  <th>Primitive Type</th>
                  <th>Complex Type</th>
                  <th>Rows</th>
                  <th colspan="2"> 
                    <Button  onClick={this.addAttribute} size="sm" variant="primary" >
                      Add New Attribute
                    </Button>
                  </th>
                </tr>
              </thead>
              <tbody>
                    {this.props.thisFullType.attributes.map((attr,i)=>{
                          return <AttributeTableRow handleDeleteAttr={this.handleDeleteAttr} attribute={attr} key={i} />;
                    })}
              </tbody>
            </Table>
          </div>
        </p>
      </div>
    )

  }
}
