import React, { Component } from "react";
import axios from 'axios';
import { Col, Row} from "react-bootstrap";
import { useLocation, useNavigate,  useParams} from "react-router-dom";
import RelationTableRow from './RelationTableRow';




function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }

  return ComponentWithRouterProp;
}


export default withRouter(

class Explain extends Component {

  constructor(props) {
    super(props)
   
    this.state = {
        KNIDRender: this.props.router.params.KNID ,    // set the starting state  --  links will reset the KNID
        Name:  'Probably the KNID was not found',
        KNID: 'KN000',
        Category: 'ERROR',
        KnowMatter: 'The root KNID should be KN11',
        RelatedKnodes: [],
        
      }

    this.handleChangeExplain = this.handleChangeExplain.bind(this)

  }

  componentDidMount() {
    this.fetchKnode(this.state.KNIDRender )  // it was set in constructor at start
  }

 fetchKnode(newKnid) {   // use for mount and to move to new explain
    axios.get('https://clammytest.assertions.ca/explain/view-full-knode/?KNID=' + newKnid)  //AMBase returns a collection because it does not query by fk  -- it includes related kedges
    .catch(function (error) {
      console.log(error.toJSON());
    })
    .then(res => {
      let expThis = res.data
      this.setState({ Name: expThis.Name })
      //this.setState({ KNIDRender: expThis.KNID })  //  no this might cause a re-render
      this.setState({ KNID: expThis.KNID })
      this.setState({ Category: expThis.Category })
      this.setState({ Description: expThis.Description})
      this.setState({ KnowMatter: expThis.KnowMatter })
      this.setState({ RelatedKnodes: expThis.RelatedKedges })  // this is counterintuitive but seems to work  -- yes it is full forward kedges and the target is used
    })
  }

handleChangeExplain(newKNID) { // pass this function down to relatives to use to nav to a new page
  this.fetchKnode(newKNID)

/* NEEDED? 


    if (this.state) 
      {console.log(JSON.stringify(this.state))
      // here 'this' seems to refer to the RelationTableRow   !!!!!
        console.log('got into handleChangeExpalin with: ' + newKNID + ' and ' + this.state.KNIDRender)
        if (newKNID !== this.state.KNIDRender)
          {this.setState({
             KNIDRender: newKNID,
             ID: newKNID
          })}                 //  This setState does not take !!!!!!!!!!!  why
        //this.setState({ KNIDRender: newKNID })   // is it really this simple-- turns out not}   
      }
 NEEDED? */

}
  RelationDataTable(obRelations) {
    if (obRelations){
      var arrRelations = Object.values(obRelations)
      return arrRelations.map((res, i) => {
        return <RelationTableRow obj={res} key={i} newKNIDHandler={this.handleChangeExplain}/>;
      });
    }
  }

 


  render() {
    return ( 
      <div className="form-wrapper">
        <Row>
          <Col>
            <p>{this.state.Category}&nbsp; &nbsp; <font size="5" ><b>{this.state.Name}</b></font>  &nbsp;  <font size="3" >({this.state.KNID})</font>       </p>
            <p><b><font color="brown" >{this.state.Description}</font></b></p>
            <div dangerouslySetInnerHTML={{__html: this.state.KnowMatter}} />    
          </Col>
          <Col>
            <p><font size="2" >This ({this.state.KNID}) <i>Explain</i> page supports browsing of the AM Knowledge base. Links lead to related information on Tasks, Panels, Concepts, Controls, etc</font></p>
            <br></br>
            <p><font size="2" >Related information can be discovered by exploring the following :-</font></p>
              {this.RelationDataTable(this.state.RelatedKnodes)}
          </Col>
        </Row>
      </div>
    )
}}
)