import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import {  Row} from "react-bootstrap";

export default class RelationTableRow extends Component {
  constructor(props) {
    super(props)
    //this.deleteAssertion = this.deleteAssertion.bind(this)
// this gets newKNIDHandler as a prop   
    this.state = {
      pageHandler: this.props.newKNIDHandler,
      targetKNID: this.props.obj.strTarKNID
    }
  }

  render() {

  

  const  resetPage= ()=>{   // to invoke the pased fucntion with targetKNID
    this.state.pageHandler(this.state.targetKNID)
  }

    return (
      <div>
        <Row>
        <span class="square border border-4">
              <p><i>{this.props.obj.strRlnDetails}:</i></p>
                <h5><Link
                  className="nav-link" 
                  onClick={() => {window.location.href = '/explain/' + this.props.obj.strTarKNID }} 
                  to={'/explain/' + this.props.obj.strTarKNID}  
               
                  >
                    {this.props.obj.strTarName}
                </Link></h5>
          
                <Button onClick={() => { resetPage() }} size="sm" variant="primary">
                  Go To {this.props.obj.strTarName}
                </Button>

                <p>{this.props.obj.strTarDescription}</p>
                <p><font size="1">{this.props.obj.strTarKNID}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{this.props.obj.strRlnCategory}&nbsp;&nbsp;&nbsp;&nbsp;{this.props.obj.strTarCategory}</font></p>
              
         
        </span>
        </Row>
      </div>
    )
  }
}

        //<Link
        //  className="nav-link" 
        //  path={"/:KNID"}     //    className="edit-link" path={"assertion/:id"}
        //  to={'/explain/' + this.props.obj.strTarKNID}   // hardcoded so when it comes off the Nav bar it has a place to open - ie intro for all  ---  when hitting explain from a particular panel the buton will pass the panel id
        //>
        //</td>  Explore
        //</tr></Link>}