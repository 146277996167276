import React, { Component } from 'react'
import AttrDisplayRow from './AttrDisplayRow';
import { Link } from 'react-router-dom';
import { Col, Row, Form} from "react-bootstrap";

export default class FrameDisplayRow extends Component {
  constructor(props) {
    super(props)
  }

    render() {
   
        {/**    need title and new frame */}
        const inputFrameStyle = {

          border: '2px solid #00A4BD', padding: '10px',
         
         };
        return (
        <Row>
          
           <div style={inputFrameStyle}>
         
                <Link
                  //className="edit-link" //path={"/edit-assertion/:id"}     //    className="edit-link" path={"assertion/:id"}
                  //reloaddocument
                  to={'/use-cubicle/' + this.props.dbid}
                  onClick={() => {window.location.href = '/use-cubicle/' + this.props.dbid; }}  
                >
                  <p><font size="5" > <b>{this.props.title}</b></font> &nbsp; &nbsp; <font size="2" color="green"><b>{this.props.authName}</b></font> </p>
                </Link>
                {this.props.attrs.map((attr, i) => {
                    return <AttrDisplayRow attr={attr} />              
                })
                }
          </div><p /></Row>
        )

    

    
    }
}