import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Button from 'react-bootstrap/Button'

const strBEURL = 'clammytest.assertions.ca'   // back end url for prod  BE
//const strBEURL = 'localhost:9000'
//    ' +  strBEURL + '


export default class AssertionTableRow extends Component {
  constructor(props) {
    super(props)
// set state for frame button
  
    this.deleteAssertion = this.deleteAssertion.bind(this)
  }


  deleteAssertion() {
    axios
      .get(
        'https://' +  strBEURL + '/assertions/delete-assertion/?id=' + this.props.obj.id,
      )
      .then((res) => {

        if (document.getElementById("messagePlace") ){
          document.getElementById("messagePlace").innerHTML =  ' OK: ' + res.data
      }

      })
      .catch((error) => {
        if (document.getElementById("messagePlace") ){
          document.getElementById("messagePlace").innerHTML =  ' ERROR: ' + error
      }
      })

    this.props.handleDeleteAttr()   // ripple up the compenets to refresh the attrs

  }

  render() {
    let typeArgs = JSON.stringify({
        strAssnGUID: this.props.obj.strGUID,
        specID: this.props.obj.SpecID,
        Name: this.props.obj.Name,
        AuthName: this.props.obj.AuthName,  // Auth Name not available here - fix
        ModelID: this.props.obj.ModelID,  // ensures the modelID is passed down in case a new type has to be created
      })

 console.log(typeArgs)
    return (
     
      <tr>
       <td>{this.props.obj.SpecID }</td>
        <td><font color="navy"><b>{this.props.obj.Name}</b></font></td>
        <td>{this.props.obj.Desc}</td>
        <td>
          <Link
            className="edit-link" //path={"/edit-assertion/:id"}     //    className="edit-link" path={"assertion/:id"}
            to={'/edit-assertion/' + this.props.obj.dbid  }
            //state= {{ dbid: this.props.obj.id }}                //should pass dbid
          >
            Edit 
          </Link>
          </td><td>
     
          <Link
            className="edit-link" //path={"/edit-assertion/:id"}     //    className="edit-link" path={"assertion/:id"}
            to={`/edit-full-type/`+ typeArgs}
            state={{ parTypeArgs: typeArgs }}
          >
            Frame
          </Link>
          <Link
            className="edit-link" //path={"/edit-assertion/:id"}     //    className="edit-link" path={"assertion/:id"}
            to={'/use-cubicle/' + this.props.obj.dbid}
          >
            Simulate
          </Link>
          </td><td>
          <Button onClick={this.deleteAssertion} size="sm" variant="danger">
            Delete
          </Button>
        </td>
      </tr>
    )
  }
}
